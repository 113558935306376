//  Variables

.app-sidebar-dover {
  width: $sidebar-width;
  position: fixed;
  right: -$sidebar-width;
  flex-direction: column;
  display: flex;
  height: 100%;
  transition: all 0.5s $app-sidebar-transition;
  z-index: 1115;
  @include media-breakpoint-up(lg) {
    &--shadow {
      box-shadow: $sidebar-shadow;
    }
  }
  &--content {
    overflow-y: auto;
    overflow-x: visible;
    height: auto;
  }

  &--collapsed {
    //width: $sidebar-width-collapsed;
    width: 0;

    .app-sidebar--header {
      justify-content: flex-end;
    }
  }
}

.app-sidebar-mobile-open {
  .app-sidebar-dover {
    right: -$sidebar-width !important;
    box-shadow: $sidebar-shadow;
  }
}

.dover-hamburger {
  padding: 0 !important;
  border-bottom: none !important;
}

// Fixed sidebar

.app-sidebar-fixed {
  .app-sidebar-dover {
    position: fixed;
    right: -$sidebar-width;

    &--content {
      height: 100%;
    }
  }
}

.app-sidebar-overlay {
  width: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity .2s;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(7, 9, 25, .4);
  z-index: 1113;

  @include media-breakpoint-down(lg) {
    &.is-active {
      opacity: 1;
      visibility: visible;
      width: 100%;
      height: 100%;
    }
  }
}

// Responsive

@include media-breakpoint-down(md) {
  .app-sidebar-dover {
    position: fixed !important;
    right: -$sidebar-width !important;
    display: block;
  }

  .app-sidebar-mobile-open {
    .app-sidebar-dover {
      right: 0 !important;
      box-shadow: $sidebar-shadow;
      display: block;
    }
  }
}

.doverContacts {
  position: absolute;
  bottom: 4.5rem;
  width: 90%;
}

.app-sidebar-dover--content::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}

.app-sidebar-dover--content {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.noScrollBar {
  min-height: 36rem;
}

.scrollShadow {
  box-shadow: none;
  transition: box-shadow 1s ease-in-out;
}

@media (max-height: 36rem) {
  .scrollShadow {
    -moz-box-shadow: 9px -2px 18px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 9px -2px 18px rgba(0, 0, 0, 0.3);
    box-shadow: 9px -2px 18px rgba(0, 0, 0, 0.3);    
  } 
}

// Modifiers

@import "modifiers/collapsed";

// Themes

@import "themes/dark";
@import "themes/light";
