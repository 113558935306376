.global-splash-screen {
    z-index: 5000;
    position: fixed;
    top: 0rem;
    left: 0rem;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 1s ease-in;

    &.phase-0 {

        svg .svg-elem-1 {
            fill: transparent;
        }

        @for $i from 2 through 26 {
            svg .svg-elem-#{$i} {
                fill: transparent;
            }
        } 
        
        svg.svg-elem-1.svg-elem-25 {
            fill: transparent;
        }
          
        svg .svg-elem-26 {
            fill: transparent;
        }
    }

    &.phase-1, &.phase-2 {

        svg .svg-elem-1 {
            fill: #c1a67c //rgb(147, 149, 152);
        }

        @for $i from 2 through 24 {
            svg .svg-elem-#{$i} {
                fill: rgb(255, 255, 255);
            }
        } 
        
        svg.svg-elem-1.svg-elem-25 {
            fill: rgb(255, 255, 255);
        }
          
          
        svg .svg-elem-26 {
            fill: rgb(255, 255, 255);
        }
    }

    &.phase-2 {
        svg {
            opacity: 0;
        }

        background-color: #c1a67c !important;
    }
    
    svg {
        opacity: 1;
        transition: opacity 0.5s ease-out;
        position: absolute;
        transform: scale(1.5);    
    }

    svg .svg-elem-1 {
        
        -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.0s;
                transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.0s;
      }
      
    svg .svg-elem-2 {
        
        -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s;
        transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s;
    }

    svg .svg-elem-3 {
    
    -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1s;
            transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1s;
    }

    svg .svg-elem-4 {
    
    -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s;
            transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s;
    }

    svg .svg-elem-5 {
    
    -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2000000000000002s;
            transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2000000000000002s;
    }

    svg .svg-elem-6 {
    
    -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3s;
            transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3s;
    }

    svg .svg-elem-7 {
    
    -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.4000000000000001s;
            transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.4000000000000001s;
    }

    svg .svg-elem-8 {
    
    -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.5s;
            transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.5s;
    }

    svg .svg-elem-9 {
    
    -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.6s;
            transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.6s;
    }

    svg .svg-elem-10 {
    
    -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7000000000000002s;
            transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7000000000000002s;
    }

    svg .svg-elem-11 { //C
    
    -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2s;
            transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2s;
    }

    svg .svg-elem-12 { //.
    
    -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2000000000000001s;
            transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2000000000000001s;
    }

    svg .svg-elem-13 {
    
    -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2s;
            transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2s;
    }

    svg .svg-elem-14 {
    
    -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.1s;
            transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.1s;
    }

    svg .svg-elem-15 {
    
    -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.2s;
            transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.2s;
    }

    svg .svg-elem-16 {
    
    -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.3s;
            transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.3s;
    }

    svg .svg-elem-17 {
    
    -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.4000000000000004s;
            transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.4000000000000004s;
    }

    svg .svg-elem-18 {
        
        -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.5s;
            transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.5s;
    }

    svg .svg-elem-19 {
        
        -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.6s;
            transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.6s;
    }

    svg .svg-elem-20 {
        
        -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.7s;
            transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.7s;
    }

    svg .svg-elem-21 {
        
        -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.8s;
            transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.8s;
    }

    svg .svg-elem-22 {
        
        -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.9000000000000004s;
            transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.9000000000000004s;
    }

    svg .svg-elem-23 {
        
        -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3s;
            transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3s;
    }

    svg .svg-elem-24 {
        
        -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.1000000000000005s;
            transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.1000000000000005s;
    }

    svg .svg-elem-25 {
        
        -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.2s;
        transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.2s;
    }

    svg .svg-elem-26 {
        
        -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.3s;
        transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.3s;
    }

    .cls-1 {
      //fill: #939598;
        fill: #907a54; 
    }

    .cls-2 {
      fill: #fff;
    }

}

/*$breakpoints: (
  xs: 576px,
  sm: 768px,
  md: 992px,
  lg: 1200px
);*/

@include media-breakpoint-down(lg) {
    .splashLogotype {
        width: 45rem;
    }
}

@include media-breakpoint-down(md) {
    .splashLogotype {
        width: 20rem;
    }
}
 
@include media-breakpoint-down(sm) {
    .splashLogotype {
        width: 10rem;
    }
}
 