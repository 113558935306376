//  Core

html,
body {
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: auto;
  height: 100%;
}

input,
textarea {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

*:not(input, textarea) {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

body {
  overflow-x: hidden;
}

.app-wrapper {
  display: flex;
  flex: 1;
  background: $page-bg;
  overflow-x: hidden;
  transition: all 0.5s $app-sidebar-transition;
}

.app-main {
  display: flex;
  flex: 1 auto;
  min-width: 0;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
}

.app-content {
  flex: 1;
  display: flex;
  padding: 0;
  flex-direction: column;
  transition: all 0.5s $app-sidebar-transition;

  &--inner {
    overflow: hidden;
    padding: 0; //$layout-spacer;

    @include media-breakpoint-down(sm) {
      padding: 0; //$layout-spacer / 3;
    }

    display: flex;
    flex: 1;

    &__wrapper {
      display: block;
      width: 100%;
    }
  }
}

// Fixed sidebar

.app-sidebar-fixed {
  .app-content {
    //padding-left: $sidebar-width;
    padding-left: 0;
  }
}

@include media-breakpoint-down(md) {
  .app-content {
    z-index: 500;
    padding-left: 0 !important;
    padding-top: 0 !important;
  }
}

// Fixed header

@include media-breakpoint-up(lg) {
  .app-header-fixed {
    .app-content {
      padding-top: 0 !important;
    }
  }
}

// Collapsed sidebar

@include media-breakpoint-up(lg) {
  .app-sidebar-collapsed {
    .app-content {
      //padding-left: $sidebar-width-collapsed;
      padding-left: 0;
    }
  }
}

.doverArrows {
  position: absolute;
  z-index: 20;
  bottom: -2rem;
  left: 0;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  img {
    //match angles of ribbon in header    
    width: 5.75rem;
    height: 6.5rem;
  }
}

.footerFlex {
  flex-direction: row;
  justify-content: center;

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
}

.slick-track {
  height: 31.5rem;
  display: flex;
  align-items: center;
}

.testimonials div div.slick-track {
  height: inherit;
}

.slick-slide {
  overflow: hidden;
  border: transparent 0.5rem solid;
  border-radius: 1.25rem;

  div div {
    height: 100%;

    a {
      margin: 0 !important;
      cursor: grab;

      img {
        object-position: center 25%;
        object-fit: cover;
        height: 31.5rem;

        &.rounded-circle {
          height: inherit;
        }
      }

      &:hover {
        .slick-text {
          color: $white;
          padding-left: 2rem;
          padding-bottom: 2.5rem;
        }

        .slick-text::after {
          height: 250%;
        }
      }

      &:active {
        cursor: grabbing;
      }
    }
  }
}

.slick-text {
  position: absolute;
  bottom: 1rem;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 1.5rem 2rem;
  color: $gray-500;
  z-index: 1;
  transition: all 0.3s ease 0s;
}

.slick-text::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.8));
  position: absolute;
  bottom: 0px;
  left: 0px;
  color: $gray-300;
  z-index: -1;
  transition: all 0.3s ease 0s;
}

.slick-prev,
.slick-next {
  background-color: $blue;
  color: $white;
}

.slick-prev {
  right: -1rem;
  left: auto !important;
}

.slick-prev>* {
  left: 1px;
}

.slick-next {
  right: auto !important;
  left: -1rem;
}

.slick-next>* {
  right: 1px;
}

.slick-mobile {
  padding-left: 1rem;

  &.testimonials {
    padding-left: 0;
  }

  @include media-breakpoint-down(md) {
    padding-left: 0;

    .slick-slide>div {
      padding-right: 0;
    }

    &.header {
      margin-left: -1rem;
      width: calc(100% + 2rem);
    }

    .headerframe {
      padding: 1.5rem 0 !important;
    }

    .slick-slide {
      border-width: 0;
      margin: 0 1rem;

      a h2 {
        border-radius: 0 1rem;
        bottom: 0;
      }
    }

    .slick-prev {
      right: 0.5rem;
    }

    .slick-next {
      left: 0.5rem;
    }
  }
}

.card-box-hover-rise {
  animation-fill-mode: backwards !important;
}

.card-box-hover-rise:hover {
  transform: translateY(0) scale(1.05) !important;
  transition: all 0.3s ease 0s;

  img {
    transition: all 0.3s ease 0s;
  }
}

.coconstructLogin {
  height: 21rem;
  width: 20rem;
}

.mediaRails {
  padding: 0 3rem;

  @include media-breakpoint-down(md) {
    padding: 0 1rem;
  }
}

.imgZoom {
  animation-fill-mode: backwards !important;
  cursor: pointer;
}

.wordBreak {
  word-break: normal;
  overflow-wrap: anywhere;
}

@include media-breakpoint-down(md) {
  .fullWidthMargin {
    padding-bottom: 0rem !important;
  }
}

.leaflet-container .leaflet-control-attribution {
  display: none;
}

.statsWrapper {
  position: inherit;
  height: 20rem;
  padding: 0 !important;
  margin: 3rem !important;
  transition: all 0.2s ease-in-out !important;
  transform: scale(1) !important;
  box-shadow: none;
  animation-fill-mode: none !important;
  cursor: pointer;

  img {
    transition: all 0.2s ease-in-out !important;
  }

  &:hover {
    .projectTitle {
      color: $white;
      padding-left: 2rem;
      padding-bottom: 2rem;
    }

    img {
      border-radius: 0.65rem !important;
      transform: scale(1.08) !important;
      box-shadow: 0 0.46875rem 2.1875rem rgba(59, 62, 102, 0.03),
        0 0.9375rem 1.40625rem rgba(59, 62, 102, 0.03),
        0 0.25rem 0.53125rem rgba(59, 62, 102, 0.05),
        0 0.125rem 0.1875rem rgba(59, 62, 102, 0.03);
    }

    .projectTitle::after {
      height: 250%;
    }
  }

  @include media-breakpoint-down(md) {
    margin: 1rem 2.25rem !important;
  }
}

.titleWrapper {
  position: inherit;
  height: 20rem;
  transition: all 0.2s ease-in-out !important;
  transform: scale(1) !important;
  box-shadow: none;
  animation-fill-mode: none !important;
  cursor: pointer;

  img {
    transition: all 0.2s ease-in-out !important;
  }

  &:hover {
    .projectTitle {
      color: $white;
      padding-left: 2rem;
      padding-bottom: 2rem;
    }

    img {
      border-radius: 0.65rem !important;
      transform: scale(1.08) !important;
      box-shadow: 0 0.46875rem 2.1875rem rgba(59, 62, 102, 0.03),
        0 0.9375rem 1.40625rem rgba(59, 62, 102, 0.03),
        0 0.25rem 0.53125rem rgba(59, 62, 102, 0.05),
        0 0.125rem 0.1875rem rgba(59, 62, 102, 0.03);
    }

    .projectTitle::after {
      height: 250%;
    }
  }
}

.projectTitle {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 1.5rem 2rem;
  text-align: left;
  color: $gray-500;
  z-index: 1;
  transition: all 0.3s ease 0s;
}

.projectTitle::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.8));
  position: absolute;
  bottom: 0px;
  left: 0px;
  color: $gray-300;
  z-index: -1;
  transition: all 0.3s ease 0s;
}

.statsCircle {
  position: absolute;
  left: 1rem;
  z-index: 10;
  color: white;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
  font-size: 5rem;
  padding: 0;
  margin: 0;
  font-weight: 600;
}

.react-photo-gallery--gallery img {
  transition: all 0.3s ease-in-out;
}

.react-photo-gallery--gallery img:hover {
  transform: scale(1.05);
  box-shadow: 0 0 0.5rem 0.1rem rgba(0, 0, 0, 0.33);
}

label.MuiFormLabel-root {
  font-size: 1.25rem !important;
}

.MuiFormLabel-root.Mui-focused {
  color: #253053 !important;
}

.MuiInputBase-input {
  color: #33ff33;
}

*:focus {
  outline: none !important;
}

.contactFields fieldset legend span {
  font-size: 1.01rem;
  padding-right: 2px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.grab-pointer {
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}

.cursor-think {
  cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'  width='30' height='36' viewport='0 0 100 100' style='fill:black;font-size:18px;'><text y='50%'>🤔</text></svg>") 16 0, auto !important;
}

.heightProcess {
  height: 36rem;
}

.verticalShift {
  height: 148%;
}

.processPosition {
  z-index: 1000;
  position: relative;
  height: calc(100% - 5rem);
  justify-content: flex-end;
  align-items: center;

  @include media-breakpoint-down(md) {
    width: 100%;
    justify-content: center;
    align-items: flex-end;
  }
}

.alignContent {
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @include media-breakpoint-down(md) {
    flex-direction: column;

    &.creative {
      width: 100%;
      justify-content: center;
      align-items: flex-end;
    }
  }
}

img.processArrow {
  position: relative;
  height: 7rem;
  left: 0;
  margin: -3.75rem;
  filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.4));

  &.creative {
    left: -2rem;
  }

  @include media-breakpoint-down(md) {
    height: 6rem;
    margin: -3.75rem;
    -webkit-transform: rotateZ(90deg);
    transform: rotateZ(90deg);

    &.creative {
      left: 0;
      top: -2rem;
    }
  }
}

.height-fit-content {
  height: fit-content;
}

.mapFrame {
  height: 40rem;
}

.leaflet-tile-pane {
  -webkit-filter: grayscale(100%) brightness(0.95);
  filter: grayscale(100%) brightness(0.95);
}

.leaflet-control-container {
  div:nth-child(1) {
    bottom: 0;
    right: 0;
    top: auto;
    left: auto;

    .leaflet-control {
      margin: 10px;
    }
  }
}

.polyOptions {
  fill: $blue;
  fill-opacity: 0.5;
  stroke: $blue;
  stroke-width: 0.25rem;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.logoShadow {
  filter: drop-shadow(0.1rem 0.1rem 0.05rem rgba(37, 48, 83, 0.75));
}

.contactForm {
  max-width: 36rem !important;
}

.cardBlocker {
  visibility: collapse;
  display: inline-table;
}