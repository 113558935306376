//  Variables

$header-height-dover:         4.5rem;
$header-height-dover-base:    5rem;
$header-bg-dover:             $first;
$header-bg-dover-transparent: rgba($first, .95);
$header-shadow:               $box-shadow-layout-bottom;
$hamburger-color:             $white;
$ribbonColor:	                $blue;

