//  Core

.dropdown-mega-menu-lg {
  width: 54rem;

  @include media-breakpoint-down(lg) {
    width: 30rem;
  }
}

.dropdown-mega-menu-md {
  width: 25rem;
}

.app-header-menu {
  position: relative;
  transition: $transition-base;
  transform: scale(1);
  visibility: visible;
  transition-delay: .2s;
  
  a {
    color: rgba(255, 255, 255, .75) !important;
  }

  a.active {
    color: $white !important;
    font-weight: 700;
  }

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.app-header-menu .MuiButton-root:hover {
  background-color: rgba(0, 0, 0, 0.08);
}