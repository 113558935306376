//  Core

.app-header-dover {
  height: $header-height-dover;
  padding: 0 ($layout-spacer / 3) 0 0;
  background: $header-bg-dover;
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 1111;
  transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);

  &--pane {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--active {
    height: $header-height-dover-base;
    background: none !important;
    box-shadow: none !important;
  }

  @include media-breakpoint-down(md) {

    .app-header-dover--pane + .app-header-dover--pane {
      transition: $transition-base;
      transform: scale(1);
      visibility: visible;
    }

  }

  &--shadow {
    box-shadow: $header-shadow;
  }

  &--opacity-bg {
    background: $header-bg-dover-transparent;
  }
  .hamburger-inner,
  .hamburger-inner:after,
  .hamburger-inner:before {
    background: $hamburger-color !important;
  }

  .toggle-mobile-sidebar-btn {
    display: none;
    padding: 0;

    .MuiButton-label {
      display: flex;
    }
  }
}

.btn-doverLogo {
  background: none;
  border: none;
  padding: 0 !important;
       
  &:focus {
    outline: none;
  }  

  &:hover {
    transform: translateY(0) !important;
    background: none !important;
  }

}

.doverLogo {
  height: $header-height-dover * 0.75;
  padding: 0 1.5rem;
  overflow: visible !important;
  animation: animates0 1s;

  g:nth-child(1) {
    transform: scale(0.6);
  }

  .cls-1 {
    fill: #fff;
  }

  .cls-2 {
    fill: #c2a67c;
  }

  .arrow1 {        
    animation: animates1 1s;
  }
  
  .arrow2 {  
    animation: animates2 1s;
  }

  @keyframes animates0 {
    0%   { transform: translateY(-0.5rem); opacity: 0; }      
    50%  { transform: translateY(-0.5rem); opacity: 0; }      
    100% { transform: translateX(0rem); opacity: 1;}       
  }

  @keyframes animates1 {
    0%   { transform: translateX(-10rem); }       
    50%  { transform: translateX(-10rem); }       
    100% { transform: translateX(0rem); }       
  }

  @keyframes animates2 {
    0%   { transform: translateX(-10rem); }       
    75%  { transform: translateX(-10rem); }       
    100% { transform: translateX(0rem); }       
  }
}

// Fixed header

@include media-breakpoint-up(lg) {
  .app-header-fixed {
    .app-header-dover {
      position: fixed;
      right: 0;
      left: 0;
      margin-left: 0;
    }
  }
}
// Fixed sidebar

@include media-breakpoint-up(lg) {
  .app-sidebar-fixed:not(.app-header-fixed) {
    .app-header-dover {
      //margin-left: $sidebar-width;
      margin-left: 0;
    }
  }
}

// Collapsed sidebar

@include media-breakpoint-up(lg) {
  .app-sidebar-collapsed:not(.app-header-fixed) {

    .app-header-dover {
      //margin-left: $sidebar-width-collapsed;
      margin-left: 0;
      left: 0;
    }

    &.app-header-fixed {
      .app-header-dover {
        left: $sidebar-width-collapsed;
        margin-left: 0;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .app-sidebar-collapsed {
      &.app-header-fixed {
        .app-header-dover {
          //left: $sidebar-width-collapsed;
          left: 0;
        }
      }
    }
  }
}
// Responsive

@include media-breakpoint-down(md) {
  .app-header-dover {
    position: fixed;
    z-index: 1100;
    width: 100%;
    flex-shrink: 0;
    padding: 0 ($layout-spacer / 2) 0 0;

    .toggle-mobile-sidebar-btn {
      display: flex;
    }
  }

  .ribbon{
    width: 3.5rem;
    height: 5rem;
    background-color: $ribbonColor;
    position: absolute;
    right: 0.75rem;
    top: -20rem;
    animation: drop forwards .8s 1s cubic-bezier(0.165, 0.84, 0.44, 1);
    -moz-box-shadow: 9px -2px 18px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 9px -2px 18px rgba(0, 0, 0, 0.3);
    box-shadow: 9px -2px 18px rgba(0, 0, 0, 0.3);   

    &:before{
      content: '';
      position: absolute;
      z-index: 2;
      left: 0;
      bottom: -0.75rem;
      border-left: 1.75rem solid $ribbonColor;
      border-right: 1.75rem solid $ribbonColor;
      border-bottom: 0.75rem solid transparent;
    }
  }

  @keyframes drop{
    0%		{ top: -20rem; }
    80%   { top: 0; height: 6rem; }
    87%   { top: 0; height: 4.75rem; }
    94%   { top: 0; height: 5.5rem; }  
    100%	{ top: 0; }
  }
}
