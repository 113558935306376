//  Core

.app-sidebar-dover {
  &--header {
    padding: ($header-height-dover / 2.5) ($layout-spacer / 2);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    z-index: 1000;

    .expand-sidebar-btn {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    svg {
      fill: white;
      position: absolute;
      left: 0.65rem;
      transform: scaley(2.2) scalex(1.25);

      &.move {
        animation: animate 0.5s;
      }

      path:nth-child(1) {
        transform: translate(0px, 0px);
      }
    }

    @keyframes animate {
      0% {
        left: 0.65rem;
        transform: rotate(0deg) scaley(3) scalex(1.75);
      }

      100% {
        left: 25rem;
        transform: rotate(0deg) scaley(2.75) scalex(1.55);
      }
    }

    &::after {
      content: "";
      width: 100%;
      left: 0;
      bottom: 0;
      height: 1px;
      position: absolute;
    }
  }

  .toggle-mobile-sidebar-btn {
    display: none;
    padding: 0;

    .MuiButton-label {
      display: flex;
    }
  }

  // Responsive

  @include media-breakpoint-down(md) {
    .toggle-mobile-sidebar-btn {
      display: block;
    }
  }
}

// Logo wrapper

.app-sidebar-logo {
  display: flex;
  align-items: center;
  transition: all 0.5s $app-sidebar-transition;

  &--icon {
    @include border-radius($border-radius-sm);
    width: 40px;
    height: 40px;
    background: $white;
    display: flex;
    align-items: center;
    align-content: center;
    box-shadow: 1px 1px 3px rgba($black, .2);

    img {
      width: 26px;
      margin: 0 auto;
      animation: logo-spin infinite 20s linear;
    }
  }

  &--text {
    line-height: 1;
    padding: 0 0 0 ($spacer / 1.5);
    transition: all 0.5s $app-sidebar-transition;

    span {
      display: block;
      text-transform: uppercase;
      font-size: $font-size-sm / 1.2;
      color: $white;
      opacity: .7;
      padding: 4px 0;
    }

    b {
      font-size: $font-size-lg * 1.1;
      font-weight: bold;
      color: $white;
      text-transform: lowercase;
    }
  }

  &:hover {
    color: $black;
  }
}

@-webkit-keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

// Themes

@import "themes/dark";
@import "themes/light";

// Modifiers

@import "modifiers/collapsed";